import { useDispatch, useSelector } from "react-redux";
import SideComparison from "../SideComparison";
import { useEffect } from "react";
import getLoans from "../../../services/LoanService";
import { handleLoanAmt } from "../../../util";

const CompareMortgages = () => {
  const { cardsToCompare } = useSelector((state) => state.comparer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoans("1"));
  }, [dispatch]);
  const fields = [
    {
      fieldName: "imgUrl",
      field1: cardsToCompare[0]?.imageUrl,
      field1Product: cardsToCompare[0]?.productName,
      field2: cardsToCompare[1]?.imageUrl,
      field2Product: cardsToCompare[1]?.productName,
      field3: cardsToCompare[2]?.imageUrl,
      field3Product: cardsToCompare[2]?.productName,
    },
    {
      fieldName: "Product Name",
      field1: cardsToCompare[0]?.productName?.substr(0, 40),
      field2: cardsToCompare[1]?.productName?.substr(0, 40),
      field3: cardsToCompare[2]?.productName?.substr(0, 40),
    },
    {
      fieldName: "Type",
      field1: cardsToCompare[0]
        ? cardsToCompare[0]?.purposePermit[0]?.toUpperCase() +
          cardsToCompare[0]?.purposePermit?.slice(1)
        : null,
      field2: cardsToCompare[1]
        ? cardsToCompare[1]?.purposePermit[0]?.toUpperCase() +
          cardsToCompare[1]?.purposePermit?.slice(1)
        : null,
      field3: cardsToCompare[2]
        ? cardsToCompare[2]?.purposePermit[0]?.toUpperCase() +
          cardsToCompare[2]?.purposePermit?.slice(1)
        : null,
    },
    {
      fieldName: "Max Loan Amount",
      field1: cardsToCompare[0]?.maxLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : handleLoanAmt(cardsToCompare[0]?.maxLoanAmt),
      field2: cardsToCompare[1]?.maxLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : handleLoanAmt(cardsToCompare[1]?.maxLoanAmt),
      field3: cardsToCompare[2]?.maxLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : handleLoanAmt(cardsToCompare[2]?.maxLoanAmt),
    },
    {
      fieldName: "Interest Rate",
      field1: cardsToCompare[0]?.intRate.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.intRate,
      field2: cardsToCompare[1]?.intRate.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.intRate,
      field3: cardsToCompare[2]?.intRate.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.intRate,
    },
    {
      fieldName: "Max. Percentage Financed",
      mobileFieldName: "Max. % Fin.",
      field1: cardsToCompare[0]?.maxPcntFin.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.maxPcntFin,
      field2: cardsToCompare[1]?.maxPcntFin.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.maxPcntFin,
      field3: cardsToCompare[2]?.maxPcntFin.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.maxPcntFin,
    },
    {
      fieldName: "Max. Repay Period",
      field1: cardsToCompare[0]?.maxRpyPeriod.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.maxRpyPeriod,
      field2: cardsToCompare[1]?.maxRpyPeriod.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.maxRpyPeriod,
      field3: cardsToCompare[2]?.maxRpyPeriod.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.maxRpyPeriod,
    },
    {
      fieldName: "Min. Loan Amount",
      field1: cardsToCompare[0]?.minLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : handleLoanAmt(cardsToCompare[0]?.minLoanAmt),
      field2: cardsToCompare[1]?.minLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : handleLoanAmt(cardsToCompare[1]?.minLoanAmt),
      field3: cardsToCompare[2]?.minLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : handleLoanAmt(cardsToCompare[2]?.minLoanAmt),
    },
    {
      fieldName: "Commitment Fee",
      field1: cardsToCompare[0]?.commitFee.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.commitFee,
      field2: cardsToCompare[1]?.commitFee.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.commitFee,
      field3: cardsToCompare[2]?.commitFee.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.commitFee,
    },
    {
      fieldName: "Processing Fee",
      field1: cardsToCompare[0]
        ? cardsToCompare[0]?.process_fee.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[0]?.process_fee
        : null,
      field2: cardsToCompare[1]
        ? cardsToCompare[1]?.process_fee.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[1]?.process_fee
        : null,
      field3: cardsToCompare[2]
        ? cardsToCompare[2]?.process_fee.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[2]?.process_fee
        : null,
    },
  ];
  return <SideComparison product={"mortgage"} fields={fields} />;
};
export default CompareMortgages;
