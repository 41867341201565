export default function isEmpty(obj) {
    let empty = false
    try {
        const objType = typeof obj
        switch (objType) {
            case "undefined":
                empty = true
                break;
            case "string":
                if (!obj.length) empty = true
                break;
            case "object":
                if (!Object.keys(obj).length) empty = true
                break;
            default:
                empty = true
                break;
        }
    } catch (error) {
        empty = true
    }

    return empty
}