import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLead } from "../../../store/reducers/LeadReducer";
import TermsOfUse from "../legal";
import { MDBTooltip } from 'mdb-react-ui-kit';

const LeadForm = () => {

  let parishes = [
    "St. Catherine",
    "St. Ann",
    "St. Elizabeth",
    "St. Andrew",
    "St. Mary",
    "Portland",
    "Kingston",
    "Manchester",
    "Clarendon",
    "Hanover",
    "Westmoreland",
    "St. James",
    "Trelawny",
    "St. Thomas"
  ]

  const ranges = [
    "$100,000 or less",
    "$100,001 - $250,000",
    "Greater than $250,000"
  ]
  const dispatch = useDispatch();

  const { errors, lead } = useSelector((state) => state.leads);


  return (
    <form className=" dz-form pb-3">
      <div className="form-group mb-3">
        <label htmlFor="email">Email</label>
        <input
          placeholder="jdoe@mail.com"
          type="email"
          className="form-control"
          value={lead?.email ?? ""}
          onChange={(e) =>
            dispatch(setLead({ lead: { ...lead, email: e.target.value } }))
          }
          autoComplete="username"
        />
        {errors?.email && (
          <div className="text-danger fs-12">{errors.email}</div>
        )}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="contact">Contact Number</label>
        <input
          placeholder="(876)123-4567"
          type="number"
          className="form-control"
          value={lead?.mobilePhone ?? ""}
          onChange={(e) =>
            dispatch(setLead({ lead: { ...lead, mobilePhone: e.target.value } }))
          }
          autoComplete="tel"
        />
        {errors?.mobilePhone && (
          <div className="text-danger fs-12">{errors.mobilePhone}</div>
        )}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="name">Full Name</label>
        <input
          type="text"
          className="form-control"
          value={lead?.fullName ?? ""}
          onChange={(e) =>
            dispatch(setLead({ lead: { ...lead, fullName: e.target.value } }))
          }
          autoComplete="full-name"
          placeholder="John Doe"
        />
        {errors?.fullName && (
          <div className="text-danger fs-12">{errors.fullName}</div>
        )}
      </div>
      <div className="form-group mb-3">
        <p className='mb-0'>
          Preferred Location{" "}
          <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title="Enter your preferred or most convenient bank location to visit so that you can be put in contact with the representative closest to you.">
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </MDBTooltip>
        </p>
        <div className="row">
          <div className="col">
            Branch
            <input
              type="text"
              className="form-control"
              value={lead?.town ?? ""}
              onChange={(e) =>
                dispatch(setLead({ lead: { ...lead, town: e.target.value } }))
              }

              placeholder="eg. Spanish Town"
            />
            {errors?.town && (
              <div className="text-danger fs-12">{errors.town}</div>
            )}
          </div>
          <div className="col">
            <div>
              Parish
              <select onChange={(e) => dispatch(setLead({ lead: { ...lead, parish: e.target.value } }))} className="form-control" defaultValue={"Select one parish"}>
                <option value={"-1"}>--- Please select one ---</option>
                {parishes.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              {errors?.parish && (
                <div className="text-danger fs-12">{errors.parish}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="form-group mb-3">
        <label htmlFor="salary-range">Salary Range</label>
        <select onChange={(e) => dispatch(setLead({ lead: { ...lead, salaryRange: e.target.value } }))} className="form-control" defaultValue={"Select Salary Range"}>
          <option value={"-1"}>--- Please select one ---</option>
          {ranges.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        {errors?.salaryRange && (
          <div className="text-danger fs-12">{errors.salaryRange}</div>
        )}
      </div>
      <div className="form-check custom-checkbox">
        <input
          type="checkbox"
          className="form-check-input"
          id="gridCheck"
          checked={lead?.acceptedTerms ?? ""}
          onChange={() => dispatch(setLead({ lead: { ...lead, acceptedTerms: !lead.acceptedTerms } }))}
        />

        <label className="form-check-label" htmlFor="gridCheck">
          By checking this box you accept our {" "}
          <TermsOfUse body={'terms'} />.
        </label>
        {errors?.acceptedTerms && (
          <div className="text-danger fs-12">{errors.acceptedTerms}</div>
        )}
      </div>
    </form>
  );
};
export default LeadForm;
