import { useDispatch, useSelector } from "react-redux";
import SideComparison from "../SideComparison";
import { useEffect } from "react";
import getAutoLoans from "../../../services/AutoLoan";
import { useLocation } from "react-router-dom";

import { splitQueryString } from "../../../util";
const CompareAutoLoans = () => {
  const { cardsToCompare } = useSelector((state) => state.comparer);
  const dispatch = useDispatch();
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      dispatch(getAutoLoans({ companyId: "1", params: splitQueryString(search) })).unwrap().then(data => {
        import("../../../store/reducers/SideComparisonReducer").then(sc => dispatch(sc.setState({
          cardsToCompare: [data[0], data[1], data[2]]
        }))).catch(err => console.log(err))
      });
    } else dispatch(getAutoLoans({ companyId: "1" }))
  }, [dispatch, search])
  const fields = [
    {
      fieldName: "imgUrl",
      field1: cardsToCompare[0]?.imageUrl,
      field1Product: cardsToCompare[0]?.productName,
      field2: cardsToCompare[1]?.imageUrl,
      field2Product: cardsToCompare[1]?.productName,
      field3: cardsToCompare[2]?.imageUrl,
      field3Product: cardsToCompare[2]?.productName,
    },
    {
      fieldName: "Product Name",
      field1: cardsToCompare[0]?.productName?.substr(0, 40),
      field2: cardsToCompare[1]?.productName?.substr(0, 40),
      field3: cardsToCompare[2]?.productName?.substr(0, 40),
    },
    {
      fieldName: "Type",
      field1: cardsToCompare[0]
        ? cardsToCompare[0]?.purposePermit[0]?.toUpperCase() +
        cardsToCompare[0]?.purposePermit?.slice(1)
        : null,
      field2: cardsToCompare[1]
        ? cardsToCompare[1]?.purposePermit[0]?.toUpperCase() +
        cardsToCompare[1]?.purposePermit?.slice(1)
        : null,
      field3: cardsToCompare[2]
        ? cardsToCompare[2]?.purposePermit[0]?.toUpperCase() +
        cardsToCompare[2]?.purposePermit?.slice(1)
        : null,
    },
    {
      fieldName: "Max Loan Amount",
      field1: cardsToCompare[0]?.maxLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.maxLoanAmt,
      field2: cardsToCompare[1]?.maxLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.maxLoanAmt,
      field3: cardsToCompare[2]?.maxLoanAmt.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.maxLoanAmt,
    },
    {
      fieldName: "Interest Rate",
      field1: cardsToCompare[0]?.intRate.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.intRate,
      field2: cardsToCompare[1]?.intRate.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.intRate,
      field3: cardsToCompare[2]?.intRate.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.intRate,
    },
    {
      fieldName: "Max. Percentage Financed",
      mobileFieldName: "Max. % Fin.",
      field1: cardsToCompare[0]?.maxPcntFin.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.maxPcntFin,
      field2: cardsToCompare[1]?.maxPcntFin.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.maxPcntFin,
      field3: cardsToCompare[2]?.maxPcntFin.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.maxPcntFin,
    },
    {
      fieldName: "Max. Repay Period",
      field1: cardsToCompare[0]?.maxRpyPeriod.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.maxRpyPeriod,
      field2: cardsToCompare[1]?.maxRpyPeriod.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.maxRpyPeriod,
      field3: cardsToCompare[2]?.maxRpyPeriod.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.maxRpyPeriod,
    },
    {
      fieldName: "Commitment Fee",
      field1: cardsToCompare[0]?.commitFee.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[0]?.commitFee,
      field2: cardsToCompare[1]?.commitFee.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[1]?.commitFee,
      field3: cardsToCompare[2]?.commitFee.toLowerCase().includes("n/a")
        ? "---"
        : cardsToCompare[2]?.commitFee,
    },
    {
      fieldName: "Vehicle Age",
      field1: cardsToCompare[0]
        ? cardsToCompare[0]?.maxVehAge.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[0]?.maxVehAge + " years"
        : null,
      field2: cardsToCompare[1]
        ? cardsToCompare[1]?.maxVehAge.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[1]?.maxVehAge + " years"
        : null,
      field3: cardsToCompare[2]
        ? cardsToCompare[2]?.maxVehAge.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[2]?.maxVehAge + " years"
        : null,
    },
  ];
  return <SideComparison product={"auto-loan"} fields={fields} />;
};
export default CompareAutoLoans;
