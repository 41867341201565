import { useDispatch, useSelector } from "react-redux";
import SideComparison from "../SideComparison";
import { currencyFormat, handleInterestFee, percent } from "../../../util";
import { getCreditCards } from "../../../services/CreditCardService";
import { useEffect } from "react";

const Compare = () => {
  const { cardsToCompare } = useSelector((state) => state.comparer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCreditCards("1"));
  }, [dispatch]);
  const fields = [
    {
      fieldName: "imgUrl",
      field1: cardsToCompare[0]?.imageUrl,
      field1Product: cardsToCompare[0]?.productName,
      field2: cardsToCompare[1]?.imageUrl,
      field2Product: cardsToCompare[1]?.productName,
      field3: cardsToCompare[2]?.imageUrl,
      field3Product: cardsToCompare[2]?.productName,
    },
    {
      fieldName: "Product Name",
      field1: cardsToCompare[0]?.productName?.substr(0, 40),
      field2: cardsToCompare[1]?.productName?.substr(0, 40),
      field3: cardsToCompare[2]?.productName?.substr(0, 40),
    },
    {
      fieldName: "Currency",
      field1:
        cardsToCompare[0]?.currency?.toLowerCase().includes("null") ||
          cardsToCompare[0]?.currency?.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[0]?.currency,
      field2:
        cardsToCompare[1]?.currency?.toLowerCase().includes("null") ||
          cardsToCompare[1]?.currency?.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[1]?.currency,
      field3:
        cardsToCompare[2]?.currency?.toLowerCase().includes("null") ||
          cardsToCompare[2]?.currency?.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[2]?.currency,
    },
    {
      fieldName: "Type",
      field1: cardsToCompare[0]
        ? cardsToCompare[0]?.type[0]?.toUpperCase() +
        cardsToCompare[0]?.type?.slice(1)
        : null,
      field2: cardsToCompare[1]
        ? cardsToCompare[1]?.type[0]?.toUpperCase() +
        cardsToCompare[1]?.type?.slice(1)
        : null,
      field3: cardsToCompare[2]
        ? cardsToCompare[2]?.type[0]?.toUpperCase() +
        cardsToCompare[2]?.type?.slice(1)
        : null,
    },
    {
      fieldName: "Interest Fee",
      field1: handleInterestFee(cardsToCompare[0]?.interestFee),
      field2: handleInterestFee(cardsToCompare[1]?.interestFee),
      field3: handleInterestFee(cardsToCompare[2]?.interestFee),
    },
    {
      fieldName: "Annual Fee",
      field1: `${currencyFormat(cardsToCompare[0]?.annualFee)}`,
      field2: `${currencyFormat(cardsToCompare[1]?.annualFee)}`,
      field3: `${currencyFormat(cardsToCompare[2]?.annualFee)}`,
    },
    {
      fieldName: "Cash Advance Fee",
      field1:
        cardsToCompare[0]?.cashAdvFee.includes("n/a") ||
          cardsToCompare[0]?.cashAdvFee.includes("null")
          ? "---"
          : cardsToCompare[0]?.cashAdvFee,
      field2:
        cardsToCompare[1]?.cashAdvFee.includes("n/a") ||
          cardsToCompare[1]?.cashAdvFee.includes("null")
          ? "---"
          : cardsToCompare[1]?.cashAdvFee,
      field3:
        cardsToCompare[2]?.cashAdvFee.includes("n/a") ||
          cardsToCompare[2]?.cashAdvFee.includes("null")
          ? "---"
          : cardsToCompare[2]?.cashAdvFee,
    },
    {
      fieldName: "Minimum Payment %",
      mobileFieldName: "Min. Payment %",
      field1: percent(cardsToCompare[0]?.minPayPcnt),
      field2: percent(cardsToCompare[1]?.minPayPcnt),
      field3: percent(cardsToCompare[2]?.minPayPcnt),
    },
    {
      fieldName: "Late Payment Fee",
      field1: currencyFormat(cardsToCompare[0]?.latePayFee),
      field2: currencyFormat(cardsToCompare[1]?.latePayFee),
      field3: currencyFormat(cardsToCompare[2]?.latePayFee),
    },
    {
      fieldName: "Over Limit Fee",
      field1: currencyFormat(cardsToCompare[0]?.overLmtFee),
      field2: currencyFormat(cardsToCompare[1]?.overLmtFee),
      field3: currencyFormat(cardsToCompare[2]?.overLmtFee),
    },
    {
      fieldName: "Rewards Program",
      field1:
        cardsToCompare[0]?.rewardsProgram.toLowerCase().includes("null") ||
          cardsToCompare[0]?.rewardsProgram.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[0]?.rewardsProgram,
      field2:
        cardsToCompare[1]?.rewardsProgram.toLowerCase().includes("null") ||
          cardsToCompare[1]?.rewardsProgram.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[1]?.rewardsProgram,
      field3:
        cardsToCompare[2]?.rewardsProgram.toLowerCase().includes("null") ||
          cardsToCompare[2]?.rewardsProgram.toLowerCase().includes("n/a")
          ? "---"
          : cardsToCompare[2]?.rewardsProgram,
    },
  ];
  return <SideComparison product={"credit-card"} fields={fields} />;
};
export default Compare;
