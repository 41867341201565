export const currencyFormat = (Amount) => {
  if (Amount) {
    return `$${parseFloat(Amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  }
  return "";
};

export const percent = (number) => {
  if (number)
    return parseFloat(number) ? `${(number * 100).toFixed(2)}%` : "---";
};

export const handleInterestFee = (fee) => {
  if (fee) {
    const feeValues = fee.split("-");
    if (feeValues.length <= 1)
      return parseFloat(feeValues[0])
        ? `${(feeValues[0] * 100).toFixed(2)}%`
        : "---";
    return parseFloat(feeValues[0]) && parseFloat(feeValues[1])
      ? `${(feeValues[0] * 100).toFixed(2)}% - ${(feeValues[1] * 100).toFixed(
        2
      )}% `
      : "---";
  }
};

export const handleLoanAmt = (amt) => {
  if (!isNaN(parseFloat(amt)) && amt.length < 12) return `$${amt}`;
  return amt;
};

const createQueryString = (qstring) => {
  if (qstring)
    return qstring.split("?").pop()
}

export const splitQueryString = (queryString) => {
  if (queryString) {
    let params = {};
    let queries = createQueryString(queryString).split('&');
    queries.forEach(query => {
      let [key, value] = query.split('=');
      params[key] = value;
    });
    return params;
  }
  return ""

}