import { createSlice } from "@reduxjs/toolkit";
import { createLead } from "../../services/LeadService";

const initialState = {
  lead: {},
  leadList: [],
  leadsLoading: false,
  errors: {},
  message: ""
};

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    setLead: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [createLead.fulfilled]: (state, { payload }) => {
      state.lead = payload.lead;
      state.leadsLoading = false;
      state.message = payload.message
    },
    [createLead.pending]: (state) => {
      state.leadsLoading = true;
    },
    [createLead.rejected]: (state, { payload }) => {
      state.errors = payload?.errors ?? payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLead } = leadSlice.actions;

export default leadSlice.reducer;
