import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configs from "../api-config";
export const getCreditCards = createAsyncThunk(
  "products/getCreditCards",
  async (companyId, thunkAPI) => {
    try {
      let response = {};
      let url = `${configs.baseUrl}/creditCard?perPage=100&pageNo=1`;
      if (companyId !== "1") {
        response = await axios.get(`${url}&companyId=${companyId}`);
      } else response = await axios.get(url);
      if (response.status === 200) return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCreditCard = createAsyncThunk(
  "products/getCreditCard",
  async ({ cardId, position }, thunkAPI) => {
    try {
      let response = {};
      let url = `${configs.baseUrl}/creditCard/${cardId}`;
      response = await axios.get(url);
      if (response.status === 200) return {data: response.data, position};
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
