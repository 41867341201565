import isEmpty from "./is-empty";
import ValidateEmail from "./validateEmail";
export const validateLead = (lead) => {
    let errors = {}
    if (isEmpty(lead.fullName)) errors.fullName = "Enter name";
    if (isEmpty(lead.mobilePhone)) errors.mobilePhone = "Enter mobile phone."
    if (!lead.acceptedTerms) errors.acceptedTerms = "Accept terms to continue."
    if (isEmpty(lead.email) || !ValidateEmail(lead.email)) errors.email = "Enter valid e-mail."
    if (isEmpty(lead.town)) errors.town ="Enter your town or district name."
    if (lead.parish === "-1") errors.parish="Please choose a parish."
    if(lead.salaryRange === "-1") errors.salaryRange = "Please select salary range."

    return { success: isEmpty(errors), errors: isEmpty(errors) ? undefined : errors }
}  