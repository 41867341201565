export default function Privacy() {
  return (
    <>
      <div className="mb-2">
        <p>
          Your privacy is important to us.
          <br />
          It is Ratehogs' policy to respect your privacy regarding any
          information we may collect while operating our website. Accordingly,
          we have developed this privacy policy in order for you to understand
          how we collect, use, communicate, disclose and otherwise make use of
          personal information. We have outlined our privacy policy below.
        </p>
      </div>
      <div className="mb-2">
        <ul className="ml-2" style={{ listStyleType: "upper-alpha" }}>
          <li>
            <p>
              We will collect personal information by lawful and fair means and,
              where appropriate, with the knowledge or consent of the individual
              concerned.
            </p>
          </li>
          <li>
            <p>
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected.
            </p>
          </li>
          <li>
            <p>
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected.
            </p>
          </li>
          <li>
            <p>
              We will protect personal information by using reasonable security
              safeguards against loss or theft, as well as unauthorized access,
              disclosure, copying, use or modification.
            </p>
          </li>
          <li>
            <p>
              We will make readily available to customers information about our
              policies and practices relating to the management of personal
              information.
            </p>
          </li>
          <li>
            <p>
              We will only retain personal information for as long as necessary
              for the fulfilment of those purposes.
            </p>
          </li>
        </ul>
        <p>
          We are committed to conducting our business in accordance with these
          principles in order to ensure that the confidentiality of personal
          information is protected and maintained. Ratehogs may change this
          privacy policy from time to time at Ratehogs' sole discretion.
        </p>
      </div>
    </>
  );
}
