import ReactGA from "react-ga4";


export const initializeReactGA = (location) =>{

    ReactGA.initialize('G-95C2QJEJF4');
    //ReactGA.page_view(`/${location}`);
   
}

