import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configs from "../api-config";
import { sortLowestToHighest } from "../util/sortRates";
export const getFXRates = createAsyncThunk(
  "products/getFXRates",
  async ({ currency = null, startDate = null, buyType = null, sortOrder, sortBy }, thunkAPI) => {
    try {
      let response = {};
      let url = `${configs.baseUrl}/fxRate/daily-rates?`;

      if (startDate) {
        url = `${url}&startDate=${startDate}`;
      }
      if (currency) {
        response = await axios.get(`${url}&currency=${currency}`);
      } else response = await axios.get(url);

      if (response.status === 200)
        return sortOrder === "asc" ? sortLowestToHighest(response.data.filter(
          (x) => x?.metadata?.companyId?.name !== "Bank of Jamaica"
        ), buyType, sortBy) : sortLowestToHighest(response.data.filter(
          (x) => x?.metadata?.companyId?.name !== "Bank of Jamaica"
        ), buyType, sortBy).reverse();
      //.filter((x) => x.companyId !== "6037f9100587700017983391");
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
