import { useState } from "react";
import { ModalBody } from "react-bootstrap";
import {
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from "react-bootstrap";
import Terms from "./Terms";
import Privacy from "./Privacy";
import { Link } from "react-router-dom";
export default function TermsOfUse({ body }) {
  const [basicModal, setBasicModal] = useState(false);
  const onClose = () => {
    setBasicModal(!basicModal);
  };
  const getDisplayText = () => {
    return body === "terms"
      ? "Terms Of Use"
      : body === "privacy policy"
        ? "Privacy Policy"
        : "";
  };
  return (
    <div className="d-inline-block">
      <Link
        className="mb-2"
        onClick={() => setBasicModal(true)}
        to="#"
      >
        {getDisplayText()}
      </Link>
      <div className="bootstrap-modal">
        <Modal className="fade" show={basicModal}>
          <ModalHeader>
            <ModalTitle>{getDisplayText()}</ModalTitle>
            <Button variant="" className="btn-close" onClick={onClose}></Button>
          </ModalHeader>
          <ModalBody>
            {body === "terms" && <Terms />}
            {body === "privacy policy" && <Privacy />}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="danger light">
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}
