import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configs from "../api-config";
const getAutoLoans = createAsyncThunk(
  "products/getAutoLoans",
  async ({ companyId, params = null }, thunkAPI) => {
    try {
      let response = {};
      let url = "";
      url = `${configs.baseUrl}/loanProducts?perPage=1000&pageNo=0&productType=autoloan`;
      if (companyId !== "1") {
        response = await axios.get(`${url}&companyId=${companyId}`, { params: { ...params } });
      } else response = await axios.get(url, { params: { ...params } });
      if (response.status === 200) return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export default getAutoLoans;

export const getAutoLoan = createAsyncThunk(
  "product/getAutoLoan",
  async ({ loanId, position }, thunkAPI) => {
    try {
      let response = {};
      let url = `${configs.baseUrl}/loanProducts/${loanId}`;
      response = await axios.get(url);
      if (response.status === 200) return { data: response.data, position };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
