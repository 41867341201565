export const createOptions = (optionList) => {
  return optionList.map((option) => {
    return { value: option._id, label: option.productName };
  });
};

export const createOption = (option) => {
  if (option) return { value: option?._id, label: option?.productName };
  return { label: "Select Option...", value: 0 };
};
