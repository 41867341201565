const DisplayImage = ({ imageUrl, productName }) => {
  return imageUrl ? (
    <div style={{ width: 100, height: "auto", margin: "auto" }}>
      <img
        className="img img-fluid"
        alt={`${productName} card`}
        src={imageUrl}
      />
    </div>
  ) : null;
};

export default DisplayImage;
