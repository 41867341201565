import React from "react";
import Select from "./SelectCardList";
import DisplayImage from "./DisplayImage";
import LeadModal from "../Leads";
import { useSelector } from "react-redux"
const DesktopTable = ({ product, comparisonFields }) => {
  const { cardsToCompare } = useSelector(state => state.comparer)
  const Table = ({ children }) => (
    <div className="desktop-table">{children}</div>
  );
  const Tr = ({ children }) => <div className="dt-row">{children}</div>;
  const Header = ({ children }) => <div className="dt-header">{children}</div>;
  const Td = ({ children }) => <div className="dt-td">{children}</div>;
  return (
    <Table>
      <Tr>
        <Header></Header>
        <Td>
          <Select position={"left"} product={product} />
        </Td>
        <Td>
          <Select position={"right"} product={product} />
        </Td>
        <Td>
          <Select position={"right-right"} product={product} />
        </Td>
      </Tr>
      {comparisonFields.map((item) =>
        item.fieldName === "imgUrl" ? (
          <Tr key={item.fieldName}>
            <Header />
            <Td>
              <DisplayImage
                imageUrl={item.field1}
                productName={item.field1Product}
              />
            </Td>
            <Td>
              <DisplayImage
                imageUrl={item.field2}
                productName={item.field2Product}
              />
            </Td>
            <Td>
              <DisplayImage
                imageUrl={item.field3}
                productName={item.field3Product}
              />
            </Td>
          </Tr>
        ) : (
          <Tr key={item.fieldName}>
            <Header>{item.fieldName}</Header>
            <Td>{item.field1}</Td>
            <Td>{item.field2}</Td>
            <Td>{item.field3}</Td>
          </Tr>
        )
      )}
      <Tr key="buttons">
        <Header />
        <Td><LeadModal item={cardsToCompare[0]} /></Td>
        <Td><LeadModal item={cardsToCompare[1]} /></Td>
        <Td><LeadModal item={cardsToCompare[2]} /></Td>
      </Tr>
    </Table>
  );
};
export default DesktopTable;
