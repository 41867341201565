import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configs from "../api-config";
const getLoans = createAsyncThunk(
  "products/getLoans",
  async (companyId, thunkAPI) => {
    try {
      let response = {};
      let url;
    
      url = `${configs.baseUrl}/loanProducts?perPage=1000&pageNo=0&productType=mortgage`;
      
      if (companyId !== "1") {
        response = await axios.get(`${url}&companyId=${companyId}`);
      } else response = await axios.get(url);
      if (response.status === 200) return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export default getLoans;

export const getMortgage = createAsyncThunk(
  "product/getMortgage",
  async ({ mortgageId, position }, thunkAPI) => {
    try {
      let response = {};
      let url = `${configs.baseUrl}/loanProducts/${mortgageId}`;
      response = await axios.get(url);
      if (response.status === 200) return { data: response.data, position };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);




