import React from "react";
import MobileTable from "./MobileTable";
import DesktopTable from "./DesktopTable";
import "./index.css";
const SideComparison = ({ product, fields }) => {
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <MobileTable product={product} comparisonFields={fields} />
          <DesktopTable product={product} comparisonFields={fields} />
        </div>
      </div>
    </div>
  );
};
export default SideComparison;
