import { createSlice } from "@reduxjs/toolkit";
import getAutoLoans from "../../services/AutoLoan";

const initialState = {
  auto: {},
  cardList: [],
  cardsLoading: false,
  errors: {},
};

export const AutoLoanSlice = createSlice({
  name: "auto-loan",
  initialState,
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [getAutoLoans.fulfilled]: (state, { payload }) => {
      state.cardList = payload;
      state.cardsLoading = false;
    },
    [getAutoLoans.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getAutoLoans.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState } = AutoLoanSlice.actions;

export default AutoLoanSlice.reducer;
