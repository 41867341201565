import { createSlice } from "@reduxjs/toolkit";
import getLoans from "../../services/LoanService";

const initialState = {
  loan: {},
  cardList: [],
  cardsToCompare: [],
  cardsLoading: false,
  errors: {},
};

export const LoanSlice = createSlice({
  name: "mortgage",
  initialState,
  reducers: {},
  extraReducers: {
    [getLoans.fulfilled]: (state, { payload }) => {
      state.cardList = payload;
      state.cardsLoading = false;
    },
    [getLoans.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getLoans.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState } = LoanSlice.actions;

export default LoanSlice.reducer;
