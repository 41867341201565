import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configs from "../api-config";
import { validateLead } from "../util/Lead";
export const createLead = createAsyncThunk(
  "products/createLead",
  async (lead, thunkAPI) => {
    try {
      let url = `${configs.baseUrl}/lead`;
      const validLead = validateLead(lead)
      
      if (validLead.success) {
        let response = await axios.post(url, lead);
        
        if (response.status !== 200)
          return thunkAPI.rejectWithValue(response.data);
        return response.data;
      } else return thunkAPI.rejectWithValue(validLead.errors);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);