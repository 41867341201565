import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFXRates } from "../../../services/FXRateService";
import { setSortOrder } from "../../../store/reducers/FXRateReducer";
const FXRates = () => {
  const [activeGenre, setActiveGenre] = useState("usd");
  const [buyType, setBuyType] = useState(true);
  const [sortBy, setSortBy] = useState("buy");

  // Get year, month, and day part from the date
  /*  var year = date.toLocaleString("default", { year: "numeric" });
  var month = date.toLocaleString("default", { month: "2-digit" });
  var day = date.toLocaleString("default", { day: "2-digit" });
  var startDate = date.toISOString().split("T").shift(); */

  const dispatch = useDispatch();
  const { rateList, ratesLoading, sortOrder } = useSelector(
    (state) => state.fxrates
  );

  useEffect(() => {
    var date = new Date();
    var today = new Date();
    today.setTime(date.getTime());
    today.setMinutes(0, 0);

    dispatch(
      getFXRates({
        currency: activeGenre,
        buyType: buyType ? null : "cheque",
        sortOrder,
        sortBy,
      })
    );
  }, [dispatch, activeGenre, buyType, sortOrder, sortBy]);

  function changeBuyType(value) {
    setBuyType(value);
  }
  function getValue(item) {
    if (item.sellRateCheque !== null) {
      return parseFloat(item.sellRateCheque).toFixed(4);
    } else {
      return parseFloat(item.sellRate).toFixed(4);
    }
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h3>Foreign Exchange Rates</h3>
        {/*  <p>Effective Date: {startDate} (Rates are subject to change)</p> */}
        <p>
          Rates last updated {new Date(rateList[0]?.updatedAt).toLocaleString()}{" "}
          (Rates are subject to change)
        </p>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card Infra">
            <div className="card-header border-0">
              <div className="site-filters clearfix center m-b40">
                <ul className="filters" data-bs-toggle="buttons">
                  <li
                    data-filter=".USD"
                    className={`btn ${activeGenre === "usd" ? "active" : ""}`}
                  >
                    <Link
                      to={"#!"}
                      className="site-button"
                      onClick={() => {
                        setActiveGenre("usd");
                      }}
                    >
                      USD
                    </Link>{" "}
                  </li>
                  <li
                    data-filter=".GBP"
                    className={`btn ${activeGenre === "gbp" ? "active" : ""}`}
                  >
                    <Link
                      to={"#!"}
                      className="site-button"
                      onClick={() => setActiveGenre("gbp")}
                    >
                      GBP
                    </Link>
                  </li>
                  <li
                    data-filter=".EUR"
                    className={`btn ${activeGenre === "eur" ? "active" : ""}`}
                  >
                    <Link
                      to={"#!"}
                      className="site-button"
                      onClick={() => setActiveGenre("eur")}
                    >
                      EURO
                    </Link>
                  </li>
                  <li
                    data-filter=".CAD"
                    className={`btn ${activeGenre === "cad" ? "active" : ""}`}
                  >
                    <Link
                      to={"#!"}
                      className="site-button"
                      onClick={() => setActiveGenre("cad")}
                    >
                      CAD
                    </Link>
                  </li>
                  <li
                    data-filter=".all"
                    className={`btn ${activeGenre === "" ? "active" : ""}`}
                  >
                    <Link
                      to={"#!"}
                      className="site-button"
                      onClick={() => setActiveGenre("")}
                    >
                      Show All
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {ratesLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !rateList.length ? (
          <div className="d-flex justify-content-center align-items-center">
            <h3>No rates to display.</h3>
          </div>
        ) : (
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-2 col-lg-4">
                <div className="card check-filter">
                  <div className="card-body site-filters clearfix">
                    <ul className="filters d-grid" data-bs-toggle="radio">
                      <li data-filter="" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          //defaultChecked
                          checked={buyType}
                          onChange={() => changeBuyType(true)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Cash
                        </label>
                      </li>
                      <li data-filter=".buyRate" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={!buyType}
                          onChange={() => changeBuyType(false)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Cheque
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="card check-filter">
                  <div className="card-body site-filters clearfix">
                    <ul className="filters d-grid" data-bs-toggle="radio">
                      <li data-filter="" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortRadioDefault"
                          id="SortRadioDefault1"
                          //defaultChecked
                          checked={sortOrder === "asc"}
                          onChange={() => dispatch(setSortOrder("asc"))}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="SortRadioDefault1"
                        >
                          Lowest to Highest
                        </label>
                      </li>
                      <li data-filter=".buyRate" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortRadioDefault"
                          id="sortRadioDefault2"
                          checked={sortOrder === "desc"}
                          onChange={() => dispatch(setSortOrder("desc"))}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sortRadioDefault2"
                        >
                          Highest to Lowest
                        </label>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="card check-filter">
                  <div className="card-body site-filters clearfix">
                    <ul className="filters d-grid" data-bs-toggle="radio">
                      <li data-filter="" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortByRadioDefault"
                          id="SortByRadioDefault1"
                          //defaultChecked
                          checked={sortBy === "buy"}
                          onChange={() => setSortBy("buy")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="SortByRadioDefault1"
                        >
                          Sort Buy Rate
                        </label>
                      </li>
                      <li data-filter=".buyRate" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortByRadioDefault"
                          id="sortByRadioDefault2"
                          checked={sortBy === "sell"}
                          onChange={() => setSortBy("sell")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sortByRadioDefault2"
                        >
                          Sort Sell Rate
                        </label>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div className="col-xl-10">
                <Card>
                  <Card.Body>
                    <Table responsive className="header-border">
                      <thead>
                        <tr>
                          <th className="text-align-center">Company</th>
                          {activeGenre === "" && (
                            <th className="text-align-center">Currency</th>
                          )}
                          <th
                            onClick={() => {
                              dispatch(
                                setSortOrder(
                                  sortOrder === "asc" ? "desc" : "asc"
                                )
                              );
                              setSortBy("buy");
                            }}
                            style={{ cursor: "pointer" }}
                            className="text-align-center"
                          >
                            {buyType ? "Buy (Cash) " : "Buy (Cheque)"}{" "}
                            <span className="ml-1">
                              {sortBy === "buy" && sortOrder === "desc" ? (
                                <i
                                  className="fa fa-arrow-down ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              ) : sortBy === "buy" && sortOrder === "asc" ? (
                                <i
                                  className="fa fa-arrow-up ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              ) : (
                                <i
                                  className="fa fa-sort ms-2 fs-14"
                                  style={{ opacity: "0.3" }}
                                />
                              )}
                            </span>
                          </th>
                          <th
                            onClick={() => {
                              dispatch(
                                setSortOrder(
                                  sortOrder === "asc" ? "desc" : "asc"
                                )
                              );
                              setSortBy("sell");
                            }}
                            style={{ cursor: "pointer" }}
                            className="text-align-center"
                          >
                            Sell{" "}
                            <span className="ml-1">
                              {sortBy === "sell" && sortOrder === "desc" ? (
                                <i
                                  className="fa fa-arrow-down ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              ) : sortBy === "sell" && sortOrder === "asc" ? (
                                <i
                                  className="fa fa-arrow-up ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              ) : (
                                <i
                                  className="fa fa-sort ms-2 fs-14"
                                  style={{ opacity: "0.3" }}
                                />
                              )}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rateList.filter(x => x.metadata?.companyId?.imageUrl).map((item, index) => (
                          <tr key={index} className="text-align-center">
                            <td>
                              <img
                                style={{ width: 50 }}
                                className="img img-fluid"
                                src={item?.metadata?.companyId?.imageUrl}
                                alt="..."
                              />
                            </td>
                            {activeGenre === "" && (
                              <td className="text-align-center">
                                {item?.metadata?.currency}
                              </td>
                            )}
                            {buyType ? (
                              <td className="text-align-center">
                                ${parseFloat(item.buyRate).toFixed(4)}
                              </td>
                            ) : (
                              <td className="text-align-center">
                                ${parseFloat(item.chequeRate).toFixed(4)}
                              </td>
                            )}
                            {buyType ? (
                              <td className="text-align-center">
                                ${parseFloat(item.sellRate).toFixed(4)}
                              </td>
                            ) : (
                              <td className="text-align-center">
                                ${getValue(item)}
                              </td>
                            )}
                          </tr>
                          
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default FXRates;
