import { createSlice } from "@reduxjs/toolkit";
import {
  getCreditCards,
 // getCreditCard,
} from "../../services/CreditCardService";

const initialState = {
  card: {},
  cardList: [],
  cardsToCompare: [],
  cardsLoading: false,
  errors: {},
};

export const creditCardSlice = createSlice({
  name: "creditcard",
  initialState,
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    /* [getCreditCard.fulfilled]: (state, { payload }) => {
      let temp = state.cardsToCompare;
      if (payload.position === "left") {
        temp = [payload.data, temp[1]];
      } else {
        temp = [temp[0], payload.data];
      }
      state.cardsToCompare = temp;
      state.cardsLoading = false;
    },
    [getCreditCard.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getCreditCard.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    }, */
    [getCreditCards.fulfilled]: (state, { payload }) => {
      state.cardList = payload;
      state.cardsLoading = false;
    },
    [getCreditCards.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getCreditCards.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState } = creditCardSlice.actions;

export default creditCardSlice.reducer;
