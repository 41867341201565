import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import getAutoLoans from "../../../services/AutoLoan";
const SingleCard = React.lazy(() => import("./SingleCard"));

const CompareAuto = () => {
  const banks = [
    { name: "All Banks", id: "1" },
    { name: "NCB", id: "6036c1b2058770001798335c" },
    { name: "Scotia", id: "6036c18c058770001798335b" },
    { name: "JN", id: "6036c1e6058770001798335e" },
    { name: "CIBC", id: "660d6a9d7ad1422dc8b74cb9" },
    { name: "Sagicor", id: "6036c1bf058770001798335d" },
    { name: "FGB", id: "6039564a1163ee00179df589" },
  ];
  const dispatch = useDispatch();
  const { cardList, cardsLoading } = useSelector((state) => state.autos);
  const [activeGenre, setActiveGenre] = useState(1);
  const [currentBank, setCurrBank] = useState(banks[0]);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    dispatch(getAutoLoans({ companyId: currentBank.id }));
  }, [dispatch, currentBank.id]);

  useEffect(() => {
    const tempList = [...cardList];
    if (activeGenre === 1) {
      const tempList1 = tempList.filter((x) => !Number.isNaN(parseFloat(x.maxLoanAmt)))
      tempList1.sort((a, b) => parseFloat(a.maxLoanAmt) - parseFloat(b.maxLoanAmt))
      const tempList2 = tempList.filter((x) => Number.isNaN(parseFloat(x.maxLoanAmt)))
      const tempList3 = [...tempList1, ...tempList2]
      return setFiltered(
        tempList3

      )
    }

    if (activeGenre === 2) {
      const tempList1 = tempList.filter((x) => !Number.isNaN(parseFloat(x.intRate)))
      tempList1.sort((a, b) => parseFloat(a.intRate) - parseFloat(b.intRate))
      const tempList2 = tempList.filter((x) => Number.isNaN(parseFloat(x.intRate)))
      const tempList3 = [...tempList1, ...tempList2]
      return setFiltered(
        tempList3

      )
    }

    if (activeGenre === 3) {
      const tempList1 = tempList.filter((x) => !Number.isNaN(parseFloat(x.maxPcntFin)))
      tempList1.sort((a, b) => parseFloat(a.maxPcntFin) - parseFloat(b.maxPcntFin))
      const tempList2 = tempList.filter((x) => Number.isNaN(parseFloat(x.maxPcntFin)))
      const tempList3 = [...tempList1, ...tempList2]
      return setFiltered(
        tempList3

      )
    }
  }, [activeGenre, cardList]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card Infra">
            <div className="card-header border-0">
              <div className="site-filters clearfix center m-b40">
                <ul className="filters" data-bs-toggle="buttons">
                  {banks.map((bank) => (
                    <li
                      key={bank.name}
                      className={`btn ${currentBank.id === bank.id ? "active" : ""
                        }`}
                    >
                      <Link
                        to={"/auto-loan"}
                        className="site-button"
                        onClick={() => setCurrBank(bank)}
                      >
                        {bank.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {cardsLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !cardList.length ? (
          <h1>No cards match your selection.</h1>
        ) : (
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-2 col-lg-4">
                <div className="card check-filter">
                  <div className="card-body site-filters clearfix">
                    <ul className="filters d-grid" data-bs-toggle="radio">
                      <li data-filter=".rated" className="form-check">

                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onChange={() => setActiveGenre(1)}
                          checked={activeGenre === 1}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Max. Loan Amount
                        </label>
                      </li>
                      <li data-filter=".notrated" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          onChange={() => setActiveGenre(2)}
                          checked={activeGenre === 2}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Interest Rate
                        </label>
                      </li>
                      <li data-filter=".spo" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          onChange={() => setActiveGenre(3)}
                          checked={activeGenre === 3}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          Max. percentage financed
                        </label>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-10">
                <ul
                  //layout
                  id="masonry"
                  className="row"
                //transition={{ duration: 0.3 }}
                >

                  <AnimatePresence>
                    {filtered.map((item, index) => {
                      return (
                        <motion.li
                          layout
                          initial={{ scale: 0, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="col-lg-12 col-md-6 infra rated px-3"
                          key={index}
                        //transition={{ duration: 0.5 }}
                        >
                          <SingleCard item={item} />
                        </motion.li>
                      );
                    })}
                  </AnimatePresence>
                </ul>
                {/* <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                // id="application-tbl1_paginate"
              >
                <Link className="paginate_button previous " to="/credit-cards">
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <span>
                  {pagination.map((number, i) => (
                    <Link
                      key={i}
                      to="/credit-cards"
                       className={`paginate_button  ${
                                            activePag.current === i ? "current" : ""
                                        } `}
                    >
                      {number}
                    </Link>
                  ))}
                </span>

                <Link className="paginate_button next" to="/credit-cards">
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default CompareAuto;
