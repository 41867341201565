import { createSlice } from "@reduxjs/toolkit";
import {
  getCreditCards,
  getCreditCard,
} from "../../services/CreditCardService";
import { createOptions } from "../../util/createOptions";
import getAutoLoans, { getAutoLoan } from "../../services/AutoLoan";
import getLoans, { getMortgage } from "../../services/LoanService";
const initialState = {
  cardList: [],
  cardsToCompare: [],
  cardsLoading: false,
  selectOptions: [],
};

export const sideComparison = createSlice({
  name: "side-comparison",
  initialState,
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [getCreditCard.fulfilled]: (state, { payload }) => {
      let temp = state.cardsToCompare;
      let { position } = payload
      let card = payload.data
      state.cardsToCompare = positionCards(temp, position, card);
      state.cardsLoading = false;
    },
    [getCreditCard.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getCreditCard.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
    [getCreditCards.fulfilled]: (state, { payload }) => {
      state.cardList = payload;
      state.cardsLoading = false;
      state.selectOptions = createOptions(payload);
    },
    [getCreditCards.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getCreditCards.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
    [getAutoLoan.fulfilled]: (state, { payload }) => {
      let temp = state.cardsToCompare;
      let { position } = payload
      let card = payload.data
      state.cardsToCompare = positionCards(temp, position, card);
      state.cardsLoading = false;
    },
    [getAutoLoan.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getAutoLoan.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
    [getAutoLoans.fulfilled]: (state, { payload }) => {
      state.cardList = payload;
      state.cardsLoading = false;
      state.selectOptions = createOptions(payload);
    },
    [getAutoLoans.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getAutoLoans.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
    [getMortgage.fulfilled]: (state, { payload }) => {
      let temp = state.cardsToCompare;
      let { position } = payload
      let card = payload.data
      state.cardsToCompare = positionCards(temp, position, card);
      state.cardsLoading = false;
    },
    [getMortgage.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getMortgage.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
    [getLoans.fulfilled]: (state, { payload }) => {
      state.cardList = payload;
      state.cardsLoading = false;
      state.selectOptions = createOptions(payload);
    },
    [getLoans.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getLoans.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
  },
});

const positionCards = (cards, position, card) => {
  let temp = [...cards];
  if (position === "left") {
    temp = [card, temp[1], temp[2]];
  } else if (position === "right") {
    temp = [temp[0], card, temp[2]];
  } else {
    temp = [temp[0], temp[1], card]
  }
  return temp
}
// Action creators are generated for each case reducer function
export const { setState } = sideComparison.actions;

export default sideComparison.reducer;
