import React from "react";
import Select from "./SelectCardList";
import DisplayImage from "./DisplayImage";
import LeadModal from "../Leads"
const MobileComparison = ({ product, comparisonFields }) => {
  return (
    <div className="mobile-table">
      <div className="mobile-table-row">
        <div className="mobile-table-td">
          <Select position={"left"} product={product} />
        </div>
        <div className="mobile-table-td">
          <Select position={"right"} product={product} />
        </div>
      </div>
      {comparisonFields.map((item) =>
        item.fieldName === "imgUrl" ? (
          <div key={item.fieldName} className="mobile-table-row">
            <div className="mobile-table-td">
              <DisplayImage
                imageUrl={item.field1}
                productName={item.field1Product}
              />
            </div>
            <div className="mobile-table-td">
              <DisplayImage
                imageUrl={item.field2}
                productName={item.field2Product}
              />
            </div>
          </div>
        ) : (
          <div key={item.fieldName} className="mobile-table-row">
            <div className="mobile-table-td">
              <span>{item.mobileFieldName ?? item.fieldName}</span>
              {item.field1}
            </div>
            <div className="mobile-table-td">
              <span>{item.mobileFieldName ?? item.fieldName}</span>
              {item.field2}
            </div>
          </div>
        )
      )}
      <div className="mobile-table-row">
        <div className="mobile-table-td"><LeadModal item={product} /></div>
        <div className="mobile-table-td"><LeadModal item={product} /></div>
      </div>
    </div>
  );
};
export default MobileComparison;
