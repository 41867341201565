import { createSlice } from "@reduxjs/toolkit";
import { getFXRates } from "../../services/FXRateService";

const initialState = {
  rate: {},
  rateList: [],
  ratesLoading: false,
  errors: {},
  sortOrder: "asc"
};

export const fxrateSlice = createSlice({
  name: "fxrate",
  initialState,
  reducers: {
    setSortOrder: (state, {payload}) => {
      state.sortOrder = payload
    }
  },
  extraReducers: {
    [getFXRates.fulfilled]: (state, { payload }) => {
      state.rateList = payload;
      state.ratesLoading = false;
    },
    [getFXRates.pending]: (state) => {
      state.ratesLoading = true;
    },
    [getFXRates.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSortOrder } =
  fxrateSlice.actions;

export default fxrateSlice.reducer;
