import React from "react";
import MobileTable from "./MobileTable";
import DesktopTable from "./DesktopTable";
import "./index.css";
const SideComparison = ({ product, fields }) => {
  return (
    <div className="col-12">
      <div style={{ textAlign: "center" }}>
        <h3>Compare Now</h3>
        {/*  <p>Effective Date: {startDate} (Rates are subject to change)</p> */}
        <p>
          Explore your options! Use the dropdown menus below to change the products being compared and find the perfect match for your needs.
        </p>
      </div>
      <div className="card">
        <div className="card-body">
          <MobileTable product={product} comparisonFields={fields} />
          <DesktopTable product={product} comparisonFields={fields} />
        </div>
      </div>
    </div>
  );
};
export default SideComparison;
